import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { motion } from "framer-motion";
import { Booking } from "../../screens/HomeScreen";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  SectionContainer,
  SectionTitleContainer,
  SectionContentContainer,
  SectionContentItemContainer,
  SectionContentIconContainer,
  SectionContentTextContainer,
  SectionContentInputContainer,
  SectionContentInput,
  ConfirmButton,
  CancelButton,
} from "../styled";
import { useQueryClient } from "react-query";
import BarLoader from "react-spinners/BarLoader";
import instructions_img from "./info.png";
import { useAddLocation } from "../../reactQueryHooks";
import tour_guide_img from "./tour-guide.png";
import ConfirmStatus from "../confirm/ConfirmStatus";

//---------------------------------------------------------

type CustomHTML = {
  isDropdownOpen: boolean;
};

export const ArrowContainer = styled.i<CustomHTML>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  max-width: 40px;
  height: 100%;
  font-size: 14px;
  transform: ${({ isDropdownOpen }: any) =>
    isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
  transition: transform 0.4s ease-in-out;
`;

//---------------------------------------------------------

const TourInfo = ({ booking }: { booking: Booking }) => {
  const hasClientLocation = booking?.client_location?.length > 0;
  const hasPickupLocation =
    booking?.pickup_location?.latitude && booking?.pickup_location?.longitude;
  const pickupIncluded = booking?.product?.pickupIncluded;
  const shouldShowLocation = pickupIncluded && !hasPickupLocation;
  const shouldPromptLocation =
    !hasClientLocation && !hasPickupLocation && pickupIncluded;
  const [isPickupInputShown, setIsPickupInputShown] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isHighlitsDropdownOpen, setIsHighlitsDropdownOpen] = useState(false);
  const [isInfoDropdownOpen, setIsInfoDropdownOpen] = useState(false);
  const [isInstructionsDropdownOpen, setIsInstructionsDropdownOpen] =
    useState(false);
  const [locationInputValue, setLocationInputValue] = useState(
    booking.client_location || ""
  );
  // const [isLocationInputOk, setIsLocationInputOk] = useState(false);
  // const ref = searchParams.get("tour");
  // const locationInputisValid = locationInputValue.trim().length > 0;
  const locationInputHasEnoughChars = locationInputValue.length > 6;
  const locationInputHasTooManyChars = locationInputValue.length > 100;

  const seats = Object.values(booking?.tickets).reduce(
    (acc, curr) => acc + curr,
    0
  );
  const tickets = Object.entries(booking?.tickets).map(
    ([key, value]) => `${value}x ${key}`
  );

  const { mutateAsync: addLocation, isLoading: isAddingLocation } =
    useAddLocation() as any;

  const handleAddLocation = async () => {
    if (!locationInputHasEnoughChars) {
      alert("Please enter a location with at least 6 characters");
      return;
    }

    if (locationInputHasTooManyChars) {
      alert("Please enter a location with less than 100 characters");
      return;
    }

    try {
      // Update the booking status in the database
      await addLocation({
        booking_id: booking._id,
        location: locationInputValue,
      });

      //reload page
      window.location.reload();

      // // Get the current booking data from the cache
      // const currentBookingData = queryClient.getQueryData(["BOOKING"]) as {
      //   task: object
      //   booking: Booking
      // }

      // // Update the booking data in the cache
      // queryClient.setQueryData(["BOOKING"], {
      //   ...currentBookingData,
      //   booking: {
      //     ...currentBookingData.booking,
      //     client_location: locationInputValue,
      //   },
      // })
    } catch (e) {
      console.log(e?.toString());
      alert(e?.toString());
    }
  };

  const [isPhotoDropdownOpen, setIsPhotoDropdownOpen] = useState(false);
  const hasCrew = booking?.task?.assignees?.length > 0;
  const img_url = booking?.pickup_location?.img_url;
  const hasPickupTime = booking?.pickup_time?.length > 0;

  const hasPickupLocationAndTime =
    booking?.pickup_location?.name.length > 0 &&
    booking?.pickup_time.length > 0;

  return (
    <>
      {hasPickupLocationAndTime && (
        <ConfirmStatus booking={booking as Booking} />
      )}
      <SectionContainer>
        <SectionTitleContainer>
          Your Getaways booking info
        </SectionTitleContainer>
        <SectionContentContainer>
          <SectionContentItemContainer>
            <SectionContentIconContainer
              style={{
                fontWeight: "bold",
              }}
            >
              Name
            </SectionContentIconContainer>
            <SectionContentTextContainer>
              {booking.client_name}
            </SectionContentTextContainer>
          </SectionContentItemContainer>
          <SectionContentItemContainer>
            <SectionContentIconContainer
              style={{
                fontWeight: "bold",
              }}
            >
              Seats
            </SectionContentIconContainer>
            <SectionContentTextContainer>
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {seats}
              </span>{" "}
              (<span>{tickets.join(", ")}</span>)
            </SectionContentTextContainer>
          </SectionContentItemContainer>
          {shouldShowLocation && (
            <SectionContentItemContainer>
              <SectionContentIconContainer
                style={{
                  fontWeight: "bold",
                }}
              >
                Hotel
              </SectionContentIconContainer>
              <SectionContentTextContainer
                style={{
                  flex: 1,
                  gap: "10px",
                }}
              >
                {booking.client_location || (
                  <i
                    style={{
                      color: "indianred",
                    }}
                    className="fa-solid fa-circle-question"
                  ></i>
                )}
                {!shouldPromptLocation && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setIsPickupInputShown(!isPickupInputShown);
                    }}
                    color={isPickupInputShown ? "error" : "primary"}
                    sx={{
                      fontSize: "12px",
                    }}
                  >
                    {isPickupInputShown ? "Close" : "Edit ✏️"}
                  </Button>
                )}
              </SectionContentTextContainer>
            </SectionContentItemContainer>
          )}
          {shouldPromptLocation && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                padding: "10px 10px 11px 10px",
                backgroundColor: "whitesmoke",
                borderRadius: "5px",
              }}
            >
              <SectionContentItemContainer>
                <SectionContentTextContainer
                  style={{
                    color: "indianred",
                    //fontWeight: "bold",
                    fontSize: "14px",
                    fontStyle: "italic",
                  }}
                >
                  <span>
                    It seems that you have not added your location details yet.
                    Please use the form below to add your{" "}
                    <span style={{ fontWeight: "bold" }}>
                      hotel name or apartment/AirBNB full address
                    </span>{" "}
                    and press submit.
                    <br /> Please be aware that if your accommodation is
                    situated in a pedestrian-only or narrow area not accessible
                    by our tour vehicles, or if it is outside the designated
                    pickup zone, we will assign the closest possible central
                    meeting point for your pickup.
                  </span>
                </SectionContentTextContainer>
              </SectionContentItemContainer>
            </div>
          )}
          {(isPickupInputShown || shouldPromptLocation) && (
            <>
              <SectionContentItemContainer>
                <SectionContentInputContainer>
                  {/* <SectionContentInput
                    autoFocus
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder="Hotel name or full address"
                    value={locationInputValue}
                    onChange={(e) => setLocationInputValue(e.target.value)}
                    style={{
                      background: "white",
                      border: "1px solid rgb(240,230,230)",
                      //boxShadow: "inset 0 0 5px silver",
                    }}
                  /> */}
                  <TextField
                    label="Hotel name or full address"
                    variant="filled"
                    fullWidth
                    autoFocus
                    autoCorrect="off"
                    autoComplete="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    value={locationInputValue}
                    onChange={(e) => setLocationInputValue(e.target.value)}
                  />
                </SectionContentInputContainer>
              </SectionContentItemContainer>

              <SectionContentItemContainer
                style={{
                  gap: "5px",
                }}
              >
                <ConfirmButton onClick={handleAddLocation}>
                  {!isAddingLocation && "SUBMIT"}
                  {isAddingLocation && (
                    <BarLoader color="#fff" loading={true} />
                  )}
                </ConfirmButton>
              </SectionContentItemContainer>
            </>
          )}
        </SectionContentContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitleContainer>Your Getaways Tour info</SectionTitleContainer>
        <SectionContentContainer>
          <SectionContentItemContainer>
            <SectionContentIconContainer>
              <i className="fa-solid fa-calendar-days"></i>
            </SectionContentIconContainer>
            <SectionContentTextContainer>
              {moment(booking?.date).format("ddd, DD MMM YYYY")}
            </SectionContentTextContainer>
          </SectionContentItemContainer>

          <SectionContentItemContainer
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <SectionContentIconContainer>
              <i className="fa-solid fa-star"></i>
            </SectionContentIconContainer>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SectionContentTextContainer>
                {booking?.product.platform_product_name}
              </SectionContentTextContainer>

              <ArrowContainer
                isDropdownOpen={isDropdownOpen}
                className={`fa-solid fa-angle-down`}
              ></ArrowContainer>
            </div>
          </SectionContentItemContainer>
          {/* image and text */}
          {isDropdownOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.2,
                },
              }}
              exit={{ opacity: 0, y: -10 }}
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "white",
                padding: "7px",
                borderRadius: "5px",
              }}
            >
              <div>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "3px",
                  }}
                  src={
                    booking?.product?.product_images[0] ||
                    "https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png"
                  }
                  alt="tour info"
                />
              </div>
              <div
                style={{
                  padding: "5px",
                }}
              >
                {booking?.product.product_short_description}
              </div>
            </motion.div>
          )}

          <SectionContentItemContainer
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsHighlitsDropdownOpen(!isHighlitsDropdownOpen)}
          >
            <SectionContentIconContainer>
              <i
                className="fa-solid fa-camera
              "
              ></i>
            </SectionContentIconContainer>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SectionContentTextContainer>
                Highlights
              </SectionContentTextContainer>

              <ArrowContainer
                isDropdownOpen={isHighlitsDropdownOpen}
                className={`fa-solid fa-angle-down`}
              ></ArrowContainer>
            </div>
          </SectionContentItemContainer>

          {isHighlitsDropdownOpen && (
            <div>
              <ul>
                {booking?.product?.highlights?.map((info, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: index / 6,
                      },
                    }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    {info}
                  </motion.li>
                ))}
              </ul>
            </div>
          )}

          <SectionContentItemContainer
            style={{
              cursor: "pointer",
            }}
            onClick={() => setIsInfoDropdownOpen(!isInfoDropdownOpen)}
          >
            <SectionContentIconContainer>
              <i
                style={{
                  fontWeight: "bold",
                }}
                className="fa-solid fa-circle-info"
              ></i>
            </SectionContentIconContainer>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SectionContentTextContainer>
                Know before you go
              </SectionContentTextContainer>

              <ArrowContainer
                isDropdownOpen={isInfoDropdownOpen}
                className={`fa-solid fa-angle-down`}
              ></ArrowContainer>
            </div>
          </SectionContentItemContainer>

          {isInfoDropdownOpen && (
            <div>
              <ul>
                {booking?.product?.additional_info?.map((info, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: index / 6,
                      },
                    }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    {info}
                  </motion.li>
                ))}
              </ul>
            </div>
          )}

          <SectionContentItemContainer
            style={{
              cursor: "pointer",
            }}
            onClick={() =>
              setIsInstructionsDropdownOpen(!isInstructionsDropdownOpen)
            }
          >
            <SectionContentIconContainer>
              <img src={instructions_img} alt="instructions" width={"30%"} />
            </SectionContentIconContainer>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SectionContentTextContainer>
                Special instructions
              </SectionContentTextContainer>

              <ArrowContainer
                isDropdownOpen={isInstructionsDropdownOpen}
                className={`fa-solid fa-angle-down`}
              ></ArrowContainer>
            </div>
          </SectionContentItemContainer>

          {isInstructionsDropdownOpen && (
            <div>
              <ul>
                {booking?.product?.special_instructions?.map((info, index) => (
                  <motion.li
                    key={index}
                    initial={{ opacity: 0, x: -10 }}
                    animate={{
                      opacity: 1,
                      x: 0,
                      transition: {
                        duration: index / 6,
                      },
                    }}
                    exit={{ opacity: 0, y: -10 }}
                  >
                    {info}
                  </motion.li>
                ))}
              </ul>
            </div>
          )}
        </SectionContentContainer>
      </SectionContainer>
      <SectionContainer>
        <SectionTitleContainer>Your Meeting Point</SectionTitleContainer>
        <SectionContentContainer>
          <SectionContentItemContainer>
            <SectionContentIconContainer>
              <i className="fa-solid fa-location-dot"></i>
            </SectionContentIconContainer>
            <SectionContentTextContainer
              style={{
                color: hasPickupLocation ? "black" : "indianred",
              }}
            >
              {hasPickupLocation
                ? booking.pickup_location.name
                : "TO BE ANNOUNCED"}
            </SectionContentTextContainer>
          </SectionContentItemContainer>
          {hasPickupLocation && (
            <SectionContentItemContainer
              onClick={() => {
                window.open(
                  `https://www.google.com.sa/maps/search/${booking.pickup_location.latitude},${booking.pickup_location.longitude}?hl=en`
                );
              }}
            >
              <SectionContentIconContainer>
                <i className="fa-solid fa-map-location-dot"></i>
              </SectionContentIconContainer>
              <SectionContentTextContainer
                style={{
                  color: hasPickupLocation ? "black" : "indianred",
                }}
              >
                <span
                  style={{
                    color: "#060",
                  }}
                >
                  Navigate to your pickup
                </span>
                <i
                  style={{
                    color: "#060",
                  }}
                  className="fa-sharp fa-solid fa-arrow-up-right-from-square"
                ></i>
              </SectionContentTextContainer>
            </SectionContentItemContainer>
          )}
          {hasPickupLocation && (
            <SectionContentItemContainer>
              <SectionContentIconContainer>
                <i className="fa-solid fa-info-circle"></i>
              </SectionContentIconContainer>
              <SectionContentTextContainer
                style={{
                  color: hasPickupLocation ? "black" : "indianred",
                }}
              >
                {booking.pickup_location.instructions}
              </SectionContentTextContainer>
            </SectionContentItemContainer>
          )}
          {img_url && (
            <>
              <SectionContentItemContainer
                onClick={() => setIsPhotoDropdownOpen(!isPhotoDropdownOpen)}
              >
                <SectionContentIconContainer>
                  <i className="fa-solid fa-eye"></i>
                </SectionContentIconContainer>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <SectionContentTextContainer>
                    See your meeting point
                  </SectionContentTextContainer>

                  <ArrowContainer
                    isDropdownOpen={isPhotoDropdownOpen}
                    className={`fa-solid fa-angle-down`}
                  ></ArrowContainer>
                </div>
              </SectionContentItemContainer>

              {isPhotoDropdownOpen && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.2,
                    },
                  }}
                  exit={{ opacity: 0, y: -10 }}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "white",
                    padding: "7px",
                    borderRadius: "5px",
                  }}
                >
                  <div>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "3px",
                      }}
                      src={img_url}
                      alt="tour info"
                    />
                  </div>
                </motion.div>
              )}
            </>
          )}
        </SectionContentContainer>
      </SectionContainer>

      <SectionContainer>
        <SectionTitleContainer>Your Meeting Time</SectionTitleContainer>
        <SectionContentContainer>
          <SectionContentItemContainer>
            <SectionContentIconContainer>
              <i className="fa-solid fa-clock"></i>
            </SectionContentIconContainer>
            <SectionContentTextContainer
              style={{
                color: hasPickupTime ? "black" : "indianred",
              }}
            >
              {hasPickupTime
                ? moment(booking.pickup_time, "HH:mm").format("h:mm A")
                : "TO BE ANNOUNCED"}
            </SectionContentTextContainer>
          </SectionContentItemContainer>
        </SectionContentContainer>
      </SectionContainer>
      <SectionContainer>
        <SectionTitleContainer>Your Getaways Tour Hosts</SectionTitleContainer>
        <SectionContentContainer>
          <SectionContentItemContainer>
            <SectionContentIconContainer>
              <img
                src={tour_guide_img}
                style={{ width: "32%" }}
                alt="tour guide icon"
              />
            </SectionContentIconContainer>
            <SectionContentTextContainer
              style={{
                color: hasCrew ? "black" : "indianred",
              }}
            >
              {hasCrew
                ? booking?.task?.assignees.join(", ")
                : "TO BE ANNOUNCED"}
            </SectionContentTextContainer>
          </SectionContentItemContainer>
        </SectionContentContainer>
      </SectionContainer>
    </>
  );
};

export default TourInfo;
