import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const AxiosClient = axios.create({
  baseURL: BASE_URL,
  //baseURL: "http://localhost:3001/",
});

export const getBookings = async (booking_id: string | undefined) => {
  const response = await AxiosClient.get(`api/tour/${booking_id}`);
  // console.log(response.data);
  return response.data;
};

//--------------------------------------------------------------------------

//WE SEND WITH BEACON API WHICH DOES NOT WAIT FOR RESPONSE (FIRE AND FORGET)
export const openSessionToDb = async (
  booking_ref: string,
  device_info: object
) => {
  //console.log("openSessionToDb", booking_ref);
  navigator.sendBeacon(
    `${BASE_URL}api/portal_sessions/open_session/${booking_ref}/${JSON.stringify(
      device_info
    )}`
  );
};

//WE SEND WITH BEACON API WHICH DOES NOT WAIT FOR RESPONSE (FIRE AND FORGET)
export const addActionToDb = (ref: string, user_action: string) => {
  //console.log("addActionToDb", ref, user_action);
  navigator.sendBeacon(
    `${BASE_URL}api/portal_sessions/add_action/${ref}/${user_action}`
  );
};

//THIS RUNS ON PAGE NOT VISIBLE AND WHEN WE SEND IT WITH AXIOS IT THE WINDOW CLOSES BEFORE RESPONSE IS RECEIVED AND BACKEND DOES NOT EXECUTE
export const closeSessionToDb = (ref: string) => {
  //console.log("closeSessionToDb", ref);
  navigator.sendBeacon(`${BASE_URL}api/portal_sessions/close_session/${ref}`);
};

//--------------------------------------------------------------------------

export const confirmStatus = async (booking_id: string) => {
  return AxiosClient.post(`api/tour/confirm_status`, { booking_id });
};

export const addLocation = async (booking_id: string, location: string) => {
  //console.log("addLocation", booking_id, location)
  return AxiosClient.post(`api/tour/add_location`, {
    booking_id,
    location,
  });
};

export const getTrackingData = async (
  booking_id: string | undefined,
  task_id: string | undefined
) => {
  return AxiosClient.post(`api/tour/get_tracking_data`, {
    booking_id: booking_id,
    task_id: task_id,
  });
};

export const getSunset = async (lat: number, lng: number) => {
  const response = await AxiosClient.get(
    `https://api.sunrise-sunset.org/json?lat=${lat}&lng=${lng}&date=today&formatted=0`
  );
  return response.data;
};

export const getPhoneNumberOnDuty = async () => {
  const response = await AxiosClient.get(
    `api/tour/on_duty/phone_number_on_duty`
  );
  return response.data;
};
